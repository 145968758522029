import logo from './logo.svg';
import './App.css';
import Aba from './components/aba';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import { useState,useEffect } from 'react';
import Chat from './pages/Chat';
import Login from './pages/Login';
import Register from './pages/Register';

function App() {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  const [state,setState]  = useState({"tutorial": "this is default"})
  const fetchData = () => {
        fetch('./api').then(response=>{
          if(response.status==200){
            return response.json()
          }
        }).then(data=>data?setState(data):console.log("cant find"))
        .then(error=>console.log(error))
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });

      if (response.ok) {
        const data = await response.json();
        setResponse(data.choices[0].message.content);
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    // <Chat/>
    
    <>
    <Router>
      <Routes>
         <Route exact path = '/' element = {<Chat/>}/>
          <Route exact path = '/login' element = {<Login/>}/>
          <Route exact path = '/register' element = {<Register/>}/>
      </Routes>  
    </Router>
    
    </>
    
  );
}

export default App;
