import React, {useState, useEffect} from 'react'
import logo from '../assets/logo.png';
import { Link, useNavigate} from "react-router-dom";





 const Login = () => {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email:'',
        password:'',
    })

    const handleLogin = () =>{
        const {email, password} = formData
        // setLoading(true)
        fetch('./api/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                email: email,
                password: password
            })
          })
          .then(response => response.json())
          .then(data => {
            if (data.message === 'Logged in successfully!') {
              localStorage.setItem('account', email);
              navigate('/');  // Navigate to the root route
            } else {
              alert('Login failed!');  // Display an alert if login is unsuccessful
            }
          })
          .catch(error => {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');  // Alert for any other errors
          });
}
    const {email, password} = formData

//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     const {user, isLoading, isError, isSuccess, message} = useSelector((state)=>state.auth)

       
//     useEffect(()=>{
//         if(isError){
//             alert(message)
//         }
//         if(isSuccess){
//             navigate('/')
//             dispatch(getAllPlots())
//         }
//         dispatch(reset)
//     },[user,isError,isSuccess,message,navigate,dispatch])
    
    const onChange = (e)=>{
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.value,
        }))
    }

    const onSubmit= (e)=>{
        e.preventDefault()
        const userData = {
            email,
            password
        }
        handleLogin()
    }

    return (
        <div
        style={{
          position:"absolute",
          top:"0px",
          right:"0px",
          bottom:"0px",
          left:"0px",
          display:'flex',
          flexDirection:'column',
          justifyContent:'flex-start',
          alignContent:'center',
          alignItems:'center',
          fontWeight:'bold'
        }}
      > 
        <div style = {{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            textAlign:'center',
            fontSize:'16px',
            width: ' 300px',
            marginTop:`calc(50vh - ${'200px'} )`
           
        }}>
            <div style = {{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',
                textAlign:'center',
                width: '100%',

            }}>
            <div style={{
                textDecoration:'none',
                color:'inherit',
                width:'100%',
                }}
                >  
                    <img src = {logo} alt ="" style = {{height: '80px',
                    position:'relative'
                    }}/>
                    <div style={{
                        fontSize:'30px',
                        marginTop:'10px',
                        marginBottom:'20px',
                    }}>Plant Culture</div>

                    <div style={{
                        fontSize:'12px',
                        marginTop:'10px',
                        marginBottom:'20px',
                        color:'red'
                    }}>Please login or sign up to continue.</div>
            </div> 

            <div style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between',
                alignContent:'center',
                alignItems:'center',
                width:'80%',
                borderBottom:'1px solid #FFFFFF',
                marginBottom:'20px',
                paddingBottom:'2px',
            }}>
                <div style ={{
                    color: '#000000',
                    cursor: "pointer",
                }}>login</div>

                <Link style={{
                    textDecoration:'none',
                    color:'inherit',
                    
                    }}
                    to="/Register"
                >  
                    <div style ={{
                        color: '#848484',
                        cursor: "pointer",
                    }}>sign up</div> 
                </Link>

            </div>

        
           <div style = {{width:'100%'}}>
           <div>
           <form onSubmit={onSubmit}>
           <div style={{
               border:'1px solid #d9d9d9',
               width:'100%',
               display:'flex',
               alignItems:'flex-start',
               marginTop:'10px',
               marginBottom:'10px',
           }}>
           
            <input 
                className = 'loginInput'
                type = 'text'
                id = 'email' 
                placeholder= "email" 
                name = "email"
                value={email}
                style={{
                    padding:'10px',
                    appearance:'none',
                    outline:'none',
                    borderBottomStyle:'hidden',
                    borderLeftStyle:'hidden',
                    borderRightStyle:'hidden',
                    borderTopStyle:'hidden',
                    fontSize:'16px',
   
                }}
                onChange={onChange}/>
           </div>
           
   
        <div style={{
                border:'1px solid #d9d9d9',
                width:'100%',
                display:'flex',
                alignItems:'flex-start',
                marginTop:'10px',
                marginBottom:'10px',
                }}>
         <input 
            type = 'password' 
            placeholder= "password" 
            id = 'password'
            name = 'password'
            value={password}
             style={{
                 padding:'10px',
                 appearance:'none',
                 outline:'none',
                 borderBottomStyle:'hidden',
                 borderLeftStyle:'hidden',
                 borderRightStyle:'hidden',
                 borderTopStyle:'hidden',
                 fontSize:'16px',
   
             }}
             onChange={onChange}/>
        </div>
   
   

        <button 
            type='submit'
            style={{
                backgroundColor:'#00ADEA',
                color:'#FFFFFF',
                width:'50%',
                fontSize:'16px',
                borderStyle:'hidden',
                paddingTop:'6px',
                paddingBottom:'6px',
                marginBottom:'20px',
                marginTop:'30px'
            }}
            value="Submit" 
            onClick = {async() => {}}><b>login</b></button>
        </form>
       </div>
       
           </div>
           
            </div>
    

        </div>
      </div>
    
      )

      
}






export default Login