import React, { useState, useEffect} from 'react'
import demeter from '../assets/demeter.png'
import logo from '../assets/logo.png'
import '../global.css'
import { Link,useNavigate} from "react-router-dom";


export const ChatBubble = (props) => {

  const newText = props.content.split('\n').map((str, index, array) => 
  index === array.length - 1 ? str : <>
    {str}
    <br />
  </>
);

  return (
        <div style={{
          paddingTop:'10px',
          paddingLeft:'10px',
          paddingBottom:'10px',
          // backgroundColor: props.id?'#696969':'#3F3F3F',
          backgroundColor: props.id?'#E3E3E3':'#FFFFFF',
          overflow:'scroll',
          width:'100%',
          flexDirection:'row',
          justifyContent: 'center',
          alignItems:'center'
        }}>
          {newText}
        </div>

  )
}


let toyChat = [
    {
        id:1,
        content:'How can I help you?'
    },
]


const Chat = () => {
    const [chat, setChat] = useState(toyChat)

    const messagesEndRef = React.createRef()
    const navigate = useNavigate()

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const checkAuth = () => {
      const s = localStorage.getItem('account')
      const count = localStorage.getItem('count')
      console.log(s,count)

      if(s !=null){
        return true
      }
      else if(!count){
        localStorage.setItem('count', 1);
        return true
      }
      else if(count<3){
        localStorage.setItem('count', +count + 1);
        return true
      }
      else{
        return false
      }
    }
  




    const callGPT = async (input) => {
        setLoading(true)
        const user = localStorage.getItem('account')
        fetch('./api/consult', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
              message: input,
              email: user?user:'NONE'
            })
          })
            .then(response => response.json())
            .then(response=> response['response'])
            .then((response) => {
                setMessage(response)
                console.log(response)
                
                let newChat = toyChat.push({
                    id: !toyChat[toyChat.length-1].id,
                    content:response
                })
                setChat(newChat)
                setLoading(false)
                
                const auth = checkAuth()
                if(!auth){
                  navigate('/login')
                }
            })
            .catch(error => console.log(error));

    };
    const testCallGPT = async (message) => {
        // e.preventDefault();
    
        try {
          const response = await fetch('./api/chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message }),
          });
    
          if (response.ok) {
            const data = await response.json();
            console.log(data.choices[0].message.content)

            let mess = data.choices[0].message.content
            setMessage(mess)
            let newChat = toyChat.push({
                id: !toyChat[toyChat.length-1].id,
                content:mess
            })
            setChat(newChat)
            setLoading(false)

            // setResponse(data.choices[0].message.content);
          } else {
            console.error('Error:', response.status);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };


    useEffect(() => {
        scrollToBottom();
    }, [chat]);


    const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };




    const handleKeyDown = (event) => {
        if (event.key === "Enter" ) {
            event.preventDefault();
            let newChat = toyChat.push({
                id: !toyChat[toyChat.length-1].id,
                content:event.target.value 
            })
            setChat(newChat)
            // testCallGPT(event.target.value)
            callGPT(event.target.value)
            
            // callGPT(event.target.value)
            // fetchFromOpenAI(event.target.value)
            // // callIndex(event.target.value) 

            console.log(message)
    
            event.target.value = ""; // clear the input field after submission
        }
    };
    
  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        width:'100vw',
        color:'black',
        height:'100vh',

    }}>



        <div style={{width:'90%', height:'90vh' ,border:'0px solid black',
                    display:'flex',
                    flexDirection:'column',
          
                    }}>
            <div style={{
                display:'flex',
                flexDirection:'row',
                width:'100%',
                justifyContent:'space-between',
                alignItems:'center',
                alignContent:'center',
                }}>

                <div style={{
                display:'flex',
                flexDirection:'row',
                width:'100%',
                justifyContent:'flex-start',
                alignItems:'center'}}>

                  <img style={{
                  height:'150px',
                  paddingBottom:'10px'
                  }}src = {demeter}/>

                  <div style={{
                  display:'flex',
                  flexDirection:'column',
                  height:'100%',
                  justifyContent:'center',
                  marginLeft:'20px'
                  }}>
                    <div style={{
                      fontSize:'30px',
                      marginBottom:'20px'
                    }}><b>
                    Hi! I am PlantGPT. Ask me a question about gardening!
                    </b></div>
                    <div>
                    A group of plant scientists taught me everything they know so I can help answer your every question!
                    </div>
                  </div>
                </div>

                <div>
                  <a href="https://www.plantculturesystems.com/">
                  <img style={{
                    height:'100px',
                    paddingBottom:'10px',

                    }}src = {logo}/>
                  </a>
                </div>
            </div>


            <div style={{
                height:'900px',
                width:'100%',
                overflowY: 'auto',
                }}>
                {toyChat.map((item, index) => (
                    <ChatBubble key={index} content = {item.content} id = {item.id}/>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div style={{
              display:'flex',
              flexDirection:'column'
            }}>
                <input  style={{
                    background: loading?'#CECECE':'#ffffff',
                    outline: 'none',
                    borderRadius:'15px',
                    padding:'10px',
                    paddingLeft:'10px',
                    color:'black',
                    border:'2px solid black',
                    //  boxShadow: '0px 10px 10px 5px rgba(0, 0, 0, 0.3)',
                    marginBottom:'0px',
                    }}
                    disabled = {loading}
                    placeholder = {loading?"Thinking...please wait":"Ask me a question!"}
                onKeyDown={handleKeyDown}/>

              <div style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignContent: 'center',
                  color: '#171717',
                  fontSize: '10px',
                  paddingTop:'20px'
              }}>
                  <a href='plantculturesystems.com' style={{
                      color: '#171717',           // Set the color to match the text
                      textDecoration: 'none'      // Removes the underline
                  }}>
                      Powered by <u>Plant Culture Systems.</u>
                  </a>
              </div>
          </div>
        </div>
        
    </div>
  )
}

export default Chat